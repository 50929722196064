import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SHARED_COLORS } from '@ess/shared/utils/consts';
import { SharedIconType, SharedStatus, SharedStatusConfig } from '@ess/shared/utils/models';

import { SharedUiChipsBaseComponent } from '../shared-ui-chips-base/shared-ui-chips-base.component';

@Component({
  selector: 'ess-shared-ui-chips-status',
  standalone: true,
  imports: [CommonModule, SharedUiChipsBaseComponent, SharedUiIconDirective],
  template: `
    <ess-shared-ui-chips-base
      [color]="_state.bgColor"
      [textColor]="_state.textColor"
      [borderColor]="_state.borderColor"
    >
      <div class="flex justify-start items-center gap-x-2 overflow-hidden">
        @if (statusDot ?? _state.defaultDot) {
          <div class="w-2 h-2 rounded-full shrink-0" [ngStyle]="{ backgroundColor: _state.iconColor }"></div>
        }
        @if (icon) {
          <i [essIcon]="icon" [ngStyle]="{ color: _state.iconColor }"></i>
        }
        <span class="truncate"><ng-content></ng-content></span>
      </div>
    </ess-shared-ui-chips-base>
  `,
  styles: ':host { @apply inline-flex max-w-full }',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiChipsStatusComponent {
  @Input() icon?: SharedIconType | string;
  @Input() statusDot?: boolean;

  @Input() set status(value: SharedStatus | null | undefined) {
    this._state = this.__getStateConfig(value);
  }

  protected _state: SharedStatusConfig = this.__getStateConfig();

  private __getStateConfig(status?: SharedStatus | null): SharedStatusConfig {
    switch (status) {
      case 'success':
        return {
          textColor: SHARED_COLORS.base.secondary,
          bgColor: SHARED_COLORS.success['50'],
          iconColor: SHARED_COLORS.success['500'],
          defaultDot: true,
        };
      case 'warning':
        return {
          textColor: SHARED_COLORS.base.secondary,
          bgColor: 'rgba(255, 168, 0, 0.15)',
          iconColor: 'rgba(255, 168, 0, 1)',
          defaultDot: true,
        };
      case 'error':
        return {
          textColor: SHARED_COLORS.base.secondary,
          bgColor: SHARED_COLORS.warn['100'],
          iconColor: SHARED_COLORS.warn.DEFAULT,
          defaultDot: true,
        };
      case 'outlined':
        return {
          textColor: SHARED_COLORS.base.tertiary,
          bgColor: SHARED_COLORS.white,
          iconColor: SHARED_COLORS['neutral-variant'].DEFAULT,
          borderColor: SHARED_COLORS['neutral-variant']['400'],
          defaultDot: true,
        };
      case 'dark':
        return {
          textColor: SHARED_COLORS.white,
          bgColor: SHARED_COLORS['neutral-variant']['800'],
          iconColor: SHARED_COLORS['neutral-variant']['600'],
          defaultDot: true,
        };
      case 'default':
      default:
        return {
          textColor: SHARED_COLORS.base.secondary,
          bgColor: SHARED_COLORS['neutral-variant']['200'],
          iconColor: SHARED_COLORS['neutral-variant']['700'],
          defaultDot: false,
        };
    }
  }
}
