@if (filter) {
  <ess-shared-ui-chips-form-chip
    class="max-w-xs"
    [class.cursor-pointer]="!disabled"
    [removable]="filter.removable!"
    [appearance]="appearance"
    [disabled]="disabled"
    (remove)="removeFilter.emit(filter)"
  >
    <div class="grid grid-flow-col overflow-hidden auto-cols-fit w-full gap-x-1">
      <span
        class="text-sm text-base-note-200 capitalize flex items-center overflow-hidden"
        [ngClass]="{ '!text-base-disabled': disabled }"
      >
        <span class="truncate">{{ filter.label || filter.property }}</span
        ><span class="shrink-0">:</span>
      </span>
      @for (value of filter.values; track value; let i = $index) {
        @if (i < 1) {
          <div class="flex text-label overflow-hidden">
            <div class="capitalize truncate" [ngClass]="{ '!text-base-disabled': disabled }">
              <span class="{{ valueClass }}" [innerHTML]="value?.label || value?.value || nullOptionName"> </span>
            </div>
          </div>
        }
      }
      @if (filter.values.length > 1) {
        <ess-shared-ui-expand-button [count]="filter.values.length - 1" [disabled]="disabled" [variant]="'chip'" />
      }
      @if (tooltip) {
        <i
          class="text-base-note-100 cursor-default"
          essIcon="info"
          [matTooltip]="tooltip"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'shared-tooltip'"
        ></i>
      }
    </div>
  </ess-shared-ui-chips-form-chip>
}
