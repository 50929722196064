@if (_defaultDisplay().length > 0) {
  <div
    class="leading-normal whitespace-normal max-w-full"
    [ngClass]="{
      'flex items-center gap-2': displayChips(),
      'flex-wrap': (recordsVisible() > 1 && displayChips()) || _expanded(),
    }"
  >
    <ng-content select="[arrayLabel]" />
    @for (item of _defaultDisplay(); track i; let i = $index; let isLast = $last) {
      <span
        class="max-w-full"
        [ngClass]="{
          'mr-2': isLast && !displayChips() && !_expanded(),
          block: displayChips(),
          'overflow-hidden': recordsVisible() === 1 && displayChips() && !_expanded(),
        }"
      >
        <ng-container *ngTemplateOutlet="itemTemp; context: { $implicit: item, tooltip: tooltips()[i] }" />
        @if (!displayChips() && (!isLast || (_expanded() && _expandable().length))) {
          <span [innerHTML]="separator()"></span>
        }
      </span>
    }
    @for (item of _expandable(); track i; let i = $index; let isLast = $last) {
      @if (_expanded()) {
        <span
          class="overflow-hidden"
          [ngClass]="{
            'whitespace-nowrap inline-block': !_expandedAnimationDone(),
            block: displayChips(),
            'align-text-bottom ml-1': !displayChips() && !_expandedAnimationDone(),
          }"
          @slideRight
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemp;
              context: { $implicit: item, tooltip: tooltips()[i + _defaultDisplay().length] }
            "
          />
          @if (!displayChips() && !isLast) {
            <span [innerHTML]="separator()"></span>
          }
        </span>
      }
    }
    @if (!_expanded()) {
      <ess-shared-ui-expand-button [count]="_expandable().length" [error]="error()" (click)="expand($event)" />
    }
  </div>
} @else {
  <ng-content select="[empty]" />
}

<ng-template #itemTemp let-item let-tooltip="tooltip">
  @if (displayChips()) {
    <ess-shared-ui-chips-status
      [matTooltip]="tooltip"
      [matTooltipDisabled]="!tooltip"
      [matTooltipClass]="'shared-tooltip'"
    >
      {{ item }}
    </ess-shared-ui-chips-status>
  } @else {
    <span
      [matTooltip]="tooltip"
      [matTooltipDisabled]="!tooltip"
      [matTooltipClass]="'shared-tooltip'"
      [innerHTML]="item"
    >
    </span>
  }
</ng-template>
