<!--TODO: potential for @empty block-->
@if (_defaultDisplay().length > 0) {
  <div class="flex gap-2 items-center max-w-full" [ngClass]="{ 'flex-wrap': recordsVisible() > 1 || _expanded() }">
    <ng-content select="[arrayLabel]" />
    @for (item of _defaultDisplay(); track item; let i = $index) {
      <ess-shared-ui-chips-form-chip
        class="overflow-hidden whitespace-nowrap"
        [disabled]="disabled()"
        [tooltip]="tooltips()[i]"
        [error]="errors()[i]"
        (remove)="remove.emit(i)"
      >
        <div class="w-full truncate">{{ item }}</div>
      </ess-shared-ui-chips-form-chip>
    }
    @for (item of _expandable(); track item; let i = $index) {
      @if (_expanded()) {
        <ess-shared-ui-chips-form-chip
          class="overflow-hidden whitespace-nowrap"
          [disabled]="disabled()"
          [tooltip]="tooltips()[i + _defaultDisplay().length]"
          [error]="errors()[i + _defaultDisplay().length]"
          @slideRight
          (remove)="remove.emit(i + _defaultDisplay().length)"
        >
          <div class="w-full truncate">{{ item }}</div>
        </ess-shared-ui-chips-form-chip>
      }
    }
    @if (!_expanded()) {
      <ess-shared-ui-expand-button
        [count]="_expandable().length"
        [disabled]="disabled()"
        [error]="_expandableErrors()"
        (click)="expand($event)"
      />
    }
  </div>
} @else {
  <ng-content select="[empty]" />
}
